<template>
  <div class="data-box">
    <van-nav-bar
        title="添加盒饭"
        left-text="返回"
        right-text=""
        left-arrow
        fixed
        z-index="1000"
        placeholder
        safe-area-inset-top
        @click-left="onClickLeft"
    />
    <div class="data-box-body">
      <div class="body-div">
        <div class="body-div-item">
          <div class="span-key">餐食名称</div>
          <div class="span-input">
            <van-field
                v-model="mealsInfo.designation"
                placeholder="请输入数量"
                maxlength="10"
            >
            </van-field>
          </div>
        </div>
        <div class="body-div-item">
          <div class="span-key">用餐方式</div>
          <div class="span-value">
            <van-radio-group v-model="mealsInfo.unitDiningWay" direction="horizontal">
              <van-radio name="0">派餐</van-radio>
              <van-radio name="1">堂食</van-radio>
            </van-radio-group>
          </div>
        </div>
        <div class="body-div-item">
          <div class="span-key">用餐类别</div>
          <div class="span-input" @click="editDiningType">
            <span>{{ mealsDiningType[mealsInfo.recycleDiningType].name }}</span>
            <i class="iconfont icon-bianji2"></i>
          </div>
        </div>
        <div class="body-div-item">
          <div class="span-key">用餐日期</div>
          <div class="span-input" @click="editDate">
            <span>{{mealsInfo.useDate ? mealsInfo.useDate : '请选择用餐日期'}}</span>
            <i class="iconfont icon-riqix"></i>
          </div>
        </div>
        <div class="body-div-item">
          <div class="span-key">餐食单价</div>
          <div class="span-input">
            <van-field
                v-model="mealsInfo.price"
                placeholder="请输入单价"
                type="number"
                maxlength="10"
                @input="inputHandler"
            />
          </div>
        </div>
        <div class="body-div-item">
          <div class="span-key">预定数量</div>
          <div class="span-input">
            <van-field
                v-model="mealsInfo.reserveNum"
                placeholder="请输入数量"
                type="digit"
                maxlength="8"
            >
            </van-field>
          </div>
        </div>
        <div class="body-div-item">
          <div class="span-key">小计</div>
          <div class="span-value">
            {{mealsInfo.price * mealsInfo.reserveNum ? (mealsInfo.price * mealsInfo.reserveNum).toFixed(2) : 0}}元
          </div>
        </div>
        <div class="body-div-item">
          <div class="span-key">取餐人员</div>
          <div class="span-input" @click="editDiner">
            <span>{{mealsInfo.person ? mealsInfo.person : '请选择跟进人员'}}</span>
            <i class="iconfont icon-bianji2"></i>
          </div>
        </div>
      </div>
      <div class="body-div">
        <div class="body-div-item" style="align-items: flex-start;">
          <div class="span-key span-top">餐食图片</div>
          <div class="span-value">
            <van-uploader name="meals" v-model="mealsInfo.imageList" :after-read="afterRead" max-count="9" accept="image/*" multiple>
              <template>
                <div class="upload-div">
                  <i class="iconfont icon-plus-round"></i>
                </div>
              </template>
            </van-uploader>
          </div>
        </div>
        <div class="body-div-item" style="align-items: flex-start;">
          <div class="span-key span-top">订餐要求</div>
          <div class="span-input">
            <van-field
                v-model="mealsInfo.useNote"
                rows="3"
                type="textarea"
                maxlength="200"
                placeholder="请输入订餐要求"
                show-word-limit
            >
            </van-field>
          </div>
        </div>
        <div class="body-div-item" style="align-items: flex-start;">
          <div class="span-key span-top">新增说明</div>
          <div class="span-input">
            <van-field
                v-model="mealsInfo.note"
                rows="3"
                type="textarea"
                maxlength="200"
                placeholder="请输入新增说明"
                show-word-limit
            >
            </van-field>
          </div>
        </div>
      </div>
    </div>
    <div class="data-box-foot">
      <van-button class="btn" round block type="default" @click="onCancel">取消</van-button>
      <van-button class="btn" round block type="info" @click="onSure">保存</van-button>
    </div>
    <van-action-sheet v-model="showDiner" :actions="mealsDiners" @select="selectDiner"/>
    <van-action-sheet v-model="showDiningType" :actions="mealsDiningType" @select="selectDiningType"/>
    <van-calendar v-model="showDiningDate" @confirm="selectDiningDate"/>
    <van-overlay :show="$store.state.showOverlay" :z-index="1010">
      <van-loading class="loading-box" type="spinner" color="#1989fa"/>
    </van-overlay>
  </div>
</template>

<script>
import {
  addEditActivityMaterial,
  activityList
} from "@/api/index";
import upload from "@/assets/common/upload";

export default {
  name: "orgMeals_details_add",
  data() {
    return {
      mealsInfo: {
        activityId: null,
        companyId: null,
        supplyCompanyId: null,
        designation: "",
        unitDiningWay: "0",
        recycleDiningType: 0,
        useDate: null,
        price: null,
        reserveNum: null,
        maxFetchNum: null,
        person: "",
        photos: "",
        imageList: [],
        useNote: "",
        note: "",
        type: 3
      },
      showDiningDate: false,
      showDiningType: false,
      mealsDiningType: [
        {type: 0,name: "早餐"},
        {type: 1,name: "午餐"},
        {type: 2,name: "晚餐"},
        {type: 3,name: "宵夜"}
      ],
      showDiner: false,
      mealsDiners: []
    };
  },
  mounted() {
    let that = this;
    that.initData();
  },
  methods:{
    initData(){
      let that = this;
      that.getActObjList();
    },
    //获取活动对象信息
    getActObjList(){
      let that = this;
      let params = {
        "actId": that.$store.getters.getActivityId,
        "companyId": that.$store.getters.getCompanyId,
        "isShow": 0,
        "pageNum": 1,
        "pageSize": 9999,
        "postType": 3,
        "type": 1
      };
      activityList(params).then(res => {
        that.$tool.resValidation(res,() => {
          let array = res.data.records;
          array.forEach(item => {
            item.activitySets.forEach(value => {
              if(value.relateType === 14){
                value.name = item.chargeName;
                that.mealsDiners.push(value);
              }
            })
          })
        })
      })
    },
    onClickLeft() {
      this.$router.go(-1);
    },
    editDate(){
      let that = this;
      that.showDiningDate = true;
    },
    selectDiningDate(date){
      let that = this;
      that.showDiningDate = false;
      this.mealsInfo.useDate = that.$tool.dateFormat(date);
    },
    editDiningType(){
      let that = this;
      that.showDiningType = true;
    },
    selectDiningType(item){
      let that = this;
      that.mealsInfo.recycleDiningType = item.type;
      that.showDiningType = false;
    },
    editDiner(){
      let that = this;
      that.showDiner = true;
    },
    selectDiner(item){
      let that = this;
      that.mealsInfo.maxFetchNum = item.chargeObjId;
      that.mealsInfo.person = item.name;
      that.showDiner = false;
    },
    afterRead(file,detail){
      let that = this;
      upload.uploadFile(file,res => {
        if(detail.name === "meals"){
          that.mealsInfo.imageList[res.index].url = res.url;
        }
      },err => {
        console.log(err);
      });
    },
    //餐食单价输入验证
    inputHandler(){
      let that = this;
      let reg = /^(([0-9]+)|([0-9]+\.)|([0-9]+\.[0-9]{1,2}))$/;
      that.mealsInfo.price = reg.test(that.mealsInfo.price) ? that.mealsInfo.price : "";
    },
    onCancel(){
      this.$router.go(-1);
    },
    onSure(){
      let that = this
      if(that.mealsInfo.designation === ""){
        that.$toast('请输入餐食名称！');
        return;
      }
      if(that.mealsInfo.useDate === null){
        that.$toast('请选择用餐日期！');
        return;
      }
      if(that.mealsInfo.price === null){
        that.$toast('请输入餐食单价！');
        return;
      }
      if(that.mealsInfo.reserveNum === null){
        that.$toast('请输入预定数量！');
        return;
      }
      if(that.mealsInfo.person === ""){
        that.$toast('请选择取餐人员！');
        return;
      }
      that.mealsInfo.activityId = that.$store.getters.getActivityId;
      that.mealsInfo.companyId = that.$store.getters.getCompanyId;
      that.mealsInfo.supplyCompanyId = that.$route.query.id;
      let array = [];
      that.mealsInfo.imageList.forEach(item => {
        array.push(item.url);
      })
      that.mealsInfo.photos = array.join(",");
      addEditActivityMaterial(that.mealsInfo).then(res => {
        that.$tool.resValidation(res,() => {
          that.$toast.success('盒饭添加成功');
          that.$router.go(-1);
        })
      })
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/css/common.scss";
.upload-div{
  width: 60px;
  height: 60px;
  line-height: 60px;
  margin-right: 10px;
  background-color: #F6F9FF;
  border-radius: 10px;
  text-align: center;
  i{
    color: #D6E3FE;
    opacity: 0.5;
  }
}
::v-deep .van-uploader__preview {
  position: relative;
  width: 60px;
  height: 60px;
  margin-right: 10px;
  .van-uploader__preview-image {
    display: block;
    width: 60px;
    height: 60px;
    overflow: hidden;
    img{
      width: 60px;
      height: 60px;
      border-radius: 10px;
    }
  }
}
</style>